<template>
  <!--
    Displays the devices common information.
  -->
  <table class="defaultTable deviceCommontable">
    <tbody>
      <tr>
        <th 
          class="font-weight-bold text-right" 
          style="width:150px;"
        >
          {{ $t('deviceDetailTableComponent.status') }}
        </th>
        <td
          class="altCell"
          colspan="2"
        >
          <span :class="device.vpnIpAddress ? 'green' : 'red'">
            <font-awesome-icon
              class="mr-1"
              :icon="device.vpnIpAddress ? 'plug' : 'ban'"
            />
            {{ device.vpnIpAddress ? 'Available' : 'Unavailable' }}
          </span>
        </td>
      </tr>

      <tr v-if="device.vpnIpAddress">
        <th class="font-weight-bold text-right">
          VPN {{ $t('deviceDetailTableComponent.ipAddress') }}
        </th>
        <td colspan="2">
          <CopyToClipboard :copy-data="device.vpnIpAddress" />
        </td>
      </tr>

      <tr v-if="device.localIp">
        <th class="font-weight-bold text-right">
          {{ $t('deviceDetailTableComponent.ipAddress') }}
        </th>
        <td colspan="2">
          <CopyToClipboard :copy-data="device.localIp" />
        </td>
      </tr>

      <tr v-if="!authenticationHasRole('zerolevel')">
        <th class="font-weight-bold text-right">
          {{ $t('deviceDetailTableComponent.password') }}
        </th>
        <td colspan="2">
          <PasswordViewer
            v-if="device.password"
            :password="device.password"
          />
        </td>
      </tr>
      <template v-if="isVnc && !authenticationHasRole('zerolevel')">
        <LoadingPlaceholder v-if="terminalLoading" />
        <template v-else>
          <tr>
            <th class="font-weight-bold text-right">
              VNC {{ $t('deviceDetailTableComponent.password') }}
            </th>
            <td colspan="2">
              <PasswordViewer
                v-if="terminal && terminal.vncPassword"
                :password="terminal.vncPassword"
                @sawPw="createViewAuditLow()"
              />
            </td>
          </tr>
          <tr>
            <th class="font-weight-bold text-right">
              VNC {{ $t('deviceDetailTableComponent.password') }} view only
            </th>
            <td colspan="2">
              <PasswordViewer
                v-if="terminal && terminal.vncViewPassword"
                :password="terminal.vncViewPassword"
                @sawPw="createViewAuditLow()"
              />
            </td>
          </tr>
        </template>
      </template>
      <template v-if="isTerminal && authenticationHasRole('scope_staff') && (authenticationHasRole('admin') || authenticationHasRole('secondlevel'))">
        <LoadingPlaceholder v-if="terminalLoading" />
        <template v-else>
          <tr>
            <th class="font-weight-bold text-right">
              Reseller {{ $t('username') }}
            </th>
            <td colspan="2">
              <PasswordViewer
                v-if="terminal && terminal.username"
                :password="terminal.username"
              />
            </td>
          </tr>
          <tr>
            <th class="font-weight-bold text-right">
              Reseller {{ $t('deviceDetailTableComponent.password') }}
            </th>
            <td colspan="2">
              <PasswordViewer
                v-if="terminal && terminal.password"
                :password="terminal.password"
              />
            </td>
          </tr>
        </template>        
      </template>
      <tr v-if="quickSupportCommandsLoading">
        <LoadingPlaceholder v-if="quickSupportCommandsLoading" />
      </tr>
      <template v-else-if="activeDevice && quickSupportCommands">
        <tr
          v-for="(command, index) in quickSupportCommands"
          :key="`quickSupportCommand-${ index }`"
        >
          <td>{{ command.name }}</td>
          <td>
            <button
              class="btn btn-primary"
              @click="executeCommand(command)"
            >
              <font-awesome-icon
                class="mr-2"
                icon="terminal"
              />
              {{ $t('execute') }}
            </button>
          </td>
          <td>
            <LoadingPlaceholder v-if="command.executing" />
            <pre
              v-else-if="command.response"
              class="p-4 pre"
            >{{ command.response }}</pre>
            <template v-else>
              {{ $t('noDataAvailable') }}
            </template>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';

export default {
  name: "DeviceDetailCommonTable",
  components: {
    CopyToClipboard: () => import('@/components/CopyToClipboard.vue'),
    PasswordViewer: () => import('@/components/PasswordViewer.vue')
  },
  mixins: [
    authenticationMixin,
  ],
  props: {
    device: {
      type: Object,
      required: true
    },
    isTerminal: {
      type: Boolean,
      default () {
        return false;
      }
    },
    installationId: {
      type: String,
      default () {
        return null;
      }
    },
    isVnc: {
      type: Boolean,
      default () {
        return false;
      }
    }
  },
  data () {
    return {
      terminalLoading: false,
      terminal: null,
      quickSupportCommandsLoading: false,
      quickSupportCommands: null
    }
  },
  computed: {
    activeDevice: function () {
      if (this.device && this.device.vpnIpAddress) {
        return true;
      }
      return false;
    }
  },
  created () {
    if(this.isTerminal || this.isVnc) {
      this.getTerminalCredentials();
    }
    if (this.device.commands) {
      this.getQuickSupportCommands();
    }
  },
  methods: {
  createViewAuditLow () {
    this.axios.get(`/CentralDeviceManagement/AddVncViewAudit?deviceId=${this.device.uuid}&installationId=${this.installationId}`).then(() => {this.$emit("reloadAuditLogs");});
  },
  getTerminalCredentials () {
    this.terminalLoading = true;
    this.axios.get(`/CentralDeviceManagement/GetTerminalCredentials?deviceUuid=${this.device.uuid}&configurationId=${this.device.activeDeviceConfigurationId}&vnc=${this.isVnc}`)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.terminal = response.data; 
      })
      .finally(() => { this.terminalLoading = false;})
    },
  getQuickSupportCommands () {
    this.quickSupportCommandsLoading = true;
    this.axios.get(`/CentralDeviceManagement/FilteredQuickSupportCommand?applicationType=${this.device.applicationType}`)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }

        this.quickSupportCommands = response.data;
        this.quickSupportCommands = this.quickSupportCommands.filter(x => this.device.commands.some(t => t.name === x.remoteCommand))
      })
      .finally(() => { this.quickSupportCommandsLoading = false;})
  },
   executeCommand (command) {
      this.$set(command, 'executing', true)
      this.$nextTick(() => {
        window.$('[data-toggle="tooltip"]').tooltip();
      });
      this.$set(command, 'response', null)
      let commandRequest = {
        'Command': command.remoteCommand,
        'Args': ''
      };
      this.axios.post(`/CentralDeviceManagement/ExecuteCommand?deviceUuid=${ this.device.uuid }`, commandRequest)
        .then((response) => {
          if (response && response.data && response.data.output) {
            this.$set(command, 'response', response.data.output)
          }
        })
        .finally(() => {
          this.$set(command, 'executing', false)
        });
  } 
  }
}
</script>
